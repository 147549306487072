import { ModalBase } from '@/components/molecules'
import { config } from '@/config'
import {
  AssessmentStepKeys,
  Breakpoint,
  ComponentColors,
  ThemeColor,
} from '@/enums'
import { LeftArrow } from '@/icons'
import { useSegment } from '@/modules/analytics'
import { ContactDataImage } from '@/pages/SelfAssessmentPage/steps/components/ContactDataStepA'
import { StepperTestimonial } from '@/pages/SelfAssessmentPage/steps/StepperTestimonial'
import { StepperProvider, useStepperStore } from '@/providers'
import { theme } from '@/theme'
import {
  dynamicObjectPropType,
  extractFunnelFromPathname,
  goToExternalUrl,
  pushEventToDataLayer,
  renderableType,
  returns,
  useChangeSearchParam,
  useIsMobile,
  useModal,
  useSteps,
  useTranslationSection,
  useTranslationSectionObject,
} from '@/utils'
import { Button } from '@surein/ui'
import { ArrowRightIcon, ChevronLeftIcon } from 'lucide-react'
import PropTypes from 'prop-types'
import { pluck, uniq } from 'ramda'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import {
  ImageFromCdn,
  LineProgress,
  SecondaryButton,
  Typography,
} from '../../atoms'
import { ShowWhen, WithWrapper } from '../../hoc'
import { Column, Row } from '../../styled'
import { Breadcrumbs } from './Breadcrumbs'
import {
  BackContainer,
  ButtonContainer,
  Container,
  ContinueButton,
  FunnelTestimonialContainer,
  SkipModalSubheader,
  StepContainer,
  StepViewport,
  StepperContainer,
  StepperHeader,
} from './styles'

const useMessages = () => {
  const commonTranslations = useTranslationSection('common')
  const { t } = useTranslation()

  return {
    back: commonTranslations('back'),
    next: t('continue'),
    skipForNow: t('skipForNow'),
    confirmSkip: t('confirmSkip'),
    rejectSkip: t('rejectSkip'),
    contactDataImageText: useTranslationSectionObject('contactDataImageText'),
    funnelTestimonial: useTranslationSectionObject('funnelTestimonial'),
  }
}

export const BackButton = ({ onBack }) => {
  const messages = useMessages()

  return (
    <BackContainer onClick={onBack}>
      <Typography color={ThemeColor.b50} singleLine variant="captionR">
        <LeftArrow stroke={ThemeColor.b50} />
        <span>{messages.back}</span>
      </Typography>
    </BackContainer>
  )
}

BackButton.propTypes = {
  onBack: PropTypes.func,
}

const Component = ({
  steps,
  initialStep,
  onCancel,
  onFinish,
  withButtons = true,
  hideLastStepButton = false,
  topRight: TopRight = returns(<div />),
  stepWrapper,
  shouldUpdateUrl,
  stepProps = {},
  stepperBreadcrumbTitles,
  onLogoClick = () => goToExternalUrl(config.landingPage),
}) => {
  const { isMobile, width } = useIsMobile(Breakpoint.md)
  const changeStepParam = useChangeSearchParam()
  const {
    nextDisabled,
    nextLoading,
    onNextClick,
    onBackClick,
    skipData,
    buttonText,
  } = useStepperStore()
  const messages = useMessages()
  const { step, isLast, isFirst, index, data, back, next, goTo } = useSteps({
    initialStep,
    steps,
    keyPropName: 'key',
  })

  const { isOpen, open, close } = useModal()

  const hasBreadcrumbs = !!stepperBreadcrumbTitles

  const analytics = useSegment()

  useEffect(() => {
    document
      .getElementById('step-container')
      .scrollTo({ top: 0, left: 0, behavior: 'smooth' })

    if (shouldUpdateUrl) {
      changeStepParam({ step })
      pushEventToDataLayer('Step Change', {
        funnel_product: extractFunnelFromPathname(),
        funnel_step: step,
      })
      analytics.page({
        name: 'Page View',
        page_name: step,
      })
    }
  }, [step])

  const handleBack = () => {
    if (onBackClick) {
      onBackClick()
    } else if (!isFirst) back()
    else onCancel?.()
  }

  const handleNext = () => {
    if (!isLast) {
      next()
    } else {
      onFinish?.()
    }
  }

  const handleSkip = () => {
    skipData.onSkipClick()
    handleNext()
  }

  const handleSkipClick = () => {
    if (skipData.withModal) {
      open()
    } else handleSkip()
  }

  const onPrimaryClick = async () => {
    try {
      await onNextClick?.()
      handleNext()
    } catch (e) {
      return e
    }
    return undefined
  }

  const { component: Step, img } = data
  const ImageSrc = stepProps[step]?.img || img

  const stepIndexes = uniq(pluck('stepIndex', steps))
  const breadcrumbSteps = [...stepIndexes, Math.max(...stepIndexes) + 1]

  return (
    <Container>
      <StepperContainer>
        <StepViewport>
          <StepperHeader hasBreadcrumbs={hasBreadcrumbs}>
            <ImageFromCdn
              height={20}
              name="SureinLogo2024"
              onClick={onLogoClick}
              type="svg"
              width={74}
            />
            {hasBreadcrumbs ? (
              <Breadcrumbs
                current={data.stepIndex}
                stepperBreadcrumbTitles={stepperBreadcrumbTitles}
                steps={breadcrumbSteps}
              />
            ) : (
              <div />
            )}
            <Row gap="14px">
              <TopRight />
            </Row>
          </StepperHeader>
          <StepContainer id="step-container" withButtons={withButtons}>
            <Column gap="24px">
              {!hasBreadcrumbs && (
                <LineProgress
                  color={ComponentColors.primary}
                  height="8px"
                  percentage={((index + 1) / steps.length) * 100}
                  width="176px"
                />
              )}
              {width < theme.breakpoints.values[Breakpoint.md] &&
                step === AssessmentStepKeys.contactData && <ContactDataImage />}
              <WithWrapper
                step={step}
                wrapper={stepWrapper}
                wrapWhen={stepWrapper}
              >
                <Step
                  goTo={goTo}
                  onBack={handleBack}
                  onNext={handleNext}
                  {...(stepProps[step] || {})}
                />
              </WithWrapper>
            </Column>
          </StepContainer>
          <ButtonContainer withSkipButton={!isFirst}>
            {skipData.skipButton && (
              <SecondaryButton onClick={handleSkipClick}>
                {skipData.skipButton}
              </SecondaryButton>
            )}
            {!hideLastStepButton && !isFirst && (
              <Button
                icon={() => <ChevronLeftIcon size={24} />}
                loading={nextLoading}
                onClick={handleBack}
                shape="square"
                size="md"
                variant="muted"
              />
            )}
            {withButtons && !stepProps[step]?.hideButtons && (
              <ContinueButton
                disabled={nextDisabled}
                loading={nextLoading}
                onClick={onPrimaryClick}
                reverted
                shape="square"
                suffixIcon={!nextDisabled && ArrowRightIcon}
                variant="primary"
              >
                {buttonText || messages.next}
              </ContinueButton>
            )}
          </ButtonContainer>
        </StepViewport>
        <ShowWhen when={!isMobile && ImageSrc}>
          <FunnelTestimonialContainer>
            {ImageSrc ? <ImageSrc /> : <StepperTestimonial />}
          </FunnelTestimonialContainer>
        </ShowWhen>
        {isOpen && (
          <ModalBase
            closable
            emoji="📑"
            header={messages.skipForNow}
            isOpen={isOpen}
            onClose={close}
            onPrimaryClick={() => {
              close()
              handleSkip()
            }}
            primaryText={messages.confirmSkip}
            secondaryText={messages.rejectSkip}
            subheader={<SkipModalSubheader>{skipData.text}</SkipModalSubheader>}
          />
        )}
      </StepperContainer>
    </Container>
  )
}

Component.propTypes = {
  hideLastStepButton: PropTypes.bool,
  initialStep: PropTypes.string,
  onCancel: PropTypes.func,
  onFinish: PropTypes.func,
  onLogoClick: PropTypes.func,
  shouldUpdateUrl: PropTypes.bool,
  stepperBreadcrumbTitles: dynamicObjectPropType,
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      component: renderableType.isRequired,
      img: PropTypes.string,
      key: PropTypes.string.isRequired,
    }),
  ).isRequired,
  stepWrapper: renderableType,
  topRight: renderableType,
  withButtons: PropTypes.bool,
}

export const Stepper = (props) => (
  <StepperProvider>
    <Component {...props} />
  </StepperProvider>
)

Stepper.propTypes = Component.propTypes

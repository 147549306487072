import { ThemeColor } from '@/enums'
import { calcYearlyPrice } from '@/pages/LocationPage/helpers'
import { formatMoney } from '@/utils'
import { useTranslation } from 'react-i18next'
import { Row } from '../../styled'
import { Typography } from '../Typography'
import { Container } from './styles'

interface YearlyPriceProps {
  insurances: {
    grossPrice: number
    paymentPeriod: string
  }[]
}

const useMessages = () => {
  const { t } = useTranslation()

  return {
    monthlyPaying: t('monthlyPaying'),
    yearlyPaying: t('yearlyPaying'),
  }
}

export const YearlyPrice = ({ insurances }: YearlyPriceProps) => {
  const messages = useMessages()

  return (
    <Container>
      <Row alignItems="center" justifyContent="space-between" width="100%">
        <Typography color={ThemeColor.b50} variant="badgeText">
          {messages.monthlyPaying}
        </Typography>
        <Typography variant="captionR">
          {formatMoney(calcYearlyPrice(insurances) / 12)}
        </Typography>
      </Row>
      <Row alignItems="center" justifyContent="space-between" width="100%">
        <Typography color={ThemeColor.b50} variant="badgeText">
          {messages.yearlyPaying}
        </Typography>
        <Typography variant="captionR">
          {formatMoney(calcYearlyPrice(insurances))}
        </Typography>
      </Row>
    </Container>
  )
}

import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useModal } from './useModal'
import { useToggable } from './useToggable'

interface UseAnimateEffectProps {
  onLeave: Dispatch<SetStateAction<string | undefined>>
  duration: number
  defaultOpened: boolean
}

export const useAnimateEffect = ({
  onLeave,
  duration,
  defaultOpened,
}: UseAnimateEffectProps) => {
  const { isOpen, close } = useModal(defaultOpened)
  const [leaving, , setLeaving] = useToggable(false)

  useEffect(() => {
    const animate = setTimeout(setLeaving, duration)
    const lifestamp = setTimeout(() => {
      close()
      onLeave(undefined)
    }, duration + 200)

    return () => {
      clearTimeout(animate)
      clearTimeout(lifestamp)
    }
  }, [])

  return { isOpen, leaving }
}

interface UseAnimatedActionProps {
  onLeave: () => void
  duration: number
}

export const useAnimatedAction = ({
  onLeave,
  duration = 400,
}: UseAnimatedActionProps) => {
  const [leaving, setLeaving] = useState(false)

  const leave = () => {
    setLeaving(true)
    const element = document.getElementById('steps')

    if (!element) return

    element.style.transform = 'translateX(100%)'
    setTimeout(() => {
      setLeaving(false)
      setTimeout(onLeave)
    }, duration)
  }

  return [leaving, leave]
}

interface UseAnimatedModalProps {
  onLeave: () => void
  duration: number
  defaultOpened: boolean
}

export const useAnimatedModal = ({
  onLeave,
  duration,
  defaultOpened,
}: UseAnimatedModalProps) => {
  const { isOpen, open, close } = useModal(defaultOpened)
  const [leaving, , toggleLeave] = useToggable(false)

  const leave = () => {
    toggleLeave()
    setTimeout(() => {
      close()
      onLeave()
      toggleLeave()
    }, duration)
  }

  return { isOpen, leaving, leave, open }
}

import { AssessmentStepKeys } from '@/enums'
import { makeBusinessTypeSubmitValue } from '../../helpers'
import { ConditionalOfferAssessment } from './BasePage'

const legalAssessmentSteps = [
  AssessmentStepKeys.beforeYouStart,
  AssessmentStepKeys.businessType,
  AssessmentStepKeys.companyFoundedDateLead,
  AssessmentStepKeys.employeesCount,
  AssessmentStepKeys.approxRevenue,
  AssessmentStepKeys.startDate,
]

const makeSubmitValues = () => ({
  approxRevenue,
  businessType,
  companyFoundedDateLead,
  ...steps
}) => ({
  ...steps,
  lastYearFinances: approxRevenue,
  businessType: makeBusinessTypeSubmitValue(businessType),
  companyFoundedDate: companyFoundedDateLead,
})

const makeStepProps = (messages) => ({
  [AssessmentStepKeys.approxRevenue]: {
    helperText: messages.helperText,
  },
})

export const LegalConditionalOffer = () => (
  <ConditionalOfferAssessment
    categoryId="LE"
    makeStepProps={makeStepProps}
    stepKeys={legalAssessmentSteps}
    transformSubmitValues={makeSubmitValues()}
  />
)

import { ThemeColor } from '@/enums'
import { TrustPilotExtended } from '@/icons/TrustPilotExtended'
import { theme } from '@/theme'
import { StarIcon } from 'lucide-react'
import { Trans, useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { ImageFromCdn, Typography } from '../../../components/atoms'
import { Column, Row } from '../../../components/styled'
import { themeColor } from '@/utils'

const StyledImageFromCdn = styled(ImageFromCdn)`
  height: 32px;
  width: 32px;
  border-radius: 50%;
`

const LightTypography = styled(Typography)`
  font-weight: 400;
`

const HighlightTypography = styled(Typography)`
  background-color: ${themeColor(ThemeColor.electric200)};
  padding: 2px 8px;
  border-radius: 6px;
  -webkit-box-decoration-break: clone;
  -o-box-decoration-break: clone;
  box-decoration-break: clone;
`

interface StepperTestimonialProps {
  stepKey: string
  imageSrc: string
}

export const StepperTestimonial = ({
  stepKey = 'default',
  imageSrc = 'mi-headshot',
}: StepperTestimonialProps) => {
  const { t } = useTranslation()

  return (
    <>
      <Row gap="4px">
        {[...Array(5)].map((_, i) => (
          <StarIcon
            // eslint-disable-next-line react/no-array-index-key
            key={i}
            color="transparent"
            fill={theme.color[ThemeColor.warning1]}
            size={18}
          />
        ))}
      </Row>
      <Typography bold variant="h4">
        <Trans
          i18nKey={`funnelTestimonials.${stepKey}.text`}
          components={[<HighlightTypography bold variant="h4" />]}
        />
      </Typography>
      <Row alignItems="center" gap="8px">
        <StyledImageFromCdn name={imageSrc} type="png" />
        <Column>
          <Typography bold variant="captionR">
            {t(`funnelTestimonials.${stepKey}.name`)}
          </Typography>
          <LightTypography variant="captionR">
            {t(`funnelTestimonials.${stepKey}.subtitle`)}
          </LightTypography>
        </Column>
      </Row>
      <TrustPilotExtended width={160} />
    </>
  )
}

import dayjs from 'dayjs'
import * as Yup from 'yup'
import { CheckoutStepKeys } from './steps'

export const makeValidationSchema = (messages) => ({
  [CheckoutStepKeys.payment]: Yup.object().shape({
    payment: Yup.object().required(messages.general),
  }),
  [CheckoutStepKeys.poa]: Yup.object().shape({
    fullname: Yup.string().required(messages.general),
    birthdate: Yup.number()
      .required(messages.general)
      .test(
        'olderThan18',
        messages.over18,
        (value) => dayjs().diff(dayjs(value), 'years') >= 18,
      )
      .typeError(messages.general),
    signature: Yup.string().required(messages.general),
  }),
})

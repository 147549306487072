type TestConfig = { weights: [number, number] } | Record<string, never>

// TODO: Remove disabled rule when the constant is used in the first component
// eslint-disable-next-line import/no-unused-modules
export const ONGOING_TESTS = {
  test1: 'test-1',
} as const

export const ONGOING_TESTS_CONFIG: Record<string, TestConfig> = {
  [ONGOING_TESTS.test1]: {
    weights: [0.5, 0.5],
  },
} as const

import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react'
import { ONGOING_TESTS_CONFIG } from './constants/ongoingTests'
import { pushEventToDataLayer } from '@/utils'

type Variant = 0 | 1

interface Variants {
  [testId: string]: Variant
}

interface ABTestContextType {
  variants: Variants
}

const ABTestContext = createContext<ABTestContextType | undefined>(undefined)

interface ABTestProviderProps {
  children: ReactNode
}

export const ABTestProvider = ({ children }: ABTestProviderProps) => {
  const [variants, setVariants] = useState<Variants>({})

  useEffect(() => {
    const storedVariants: Variants = {}

    Object.entries(ONGOING_TESTS_CONFIG).forEach(([testId, test]) => {
      const testName = `abTest_${testId}`
      const storedVariant = localStorage.getItem(testName)

      if (storedVariant) {
        storedVariants[testId] = parseInt(storedVariant) as Variant

        // * Google Analytics just requires the event to be pushed to the data layer once
        // * so we push the event in the provider
        pushEventToDataLayer({
          event: testName,
          variant: storedVariants[testId],
        })
      } else {
        const weights = test.weights ?? [0.5, 0.5]
        const newVariant: Variant = Math.random() < weights[0] ? 0 : 1
        localStorage.setItem(testName, newVariant.toString())
        storedVariants[testId] = newVariant

        // * Google Analytics just requires the event to be pushed to the data layer once
        // * so we push the event in the provider
        pushEventToDataLayer({
          event: testName,
          variant: storedVariants[testId],
        })
      }
    })

    setVariants(storedVariants)
  }, [])

  return (
    <ABTestContext.Provider value={{ variants }}>
      {children}
    </ABTestContext.Provider>
  )
}

// Custom hook to use the A/B test

export const useABTest = (testId: string) => {
  const context = useContext(ABTestContext)
  if (context === undefined) {
    throw new Error('useABTest must be used within an ABTestProvider')
  }

  const variant = context.variants[testId]

  return { variant }
}

import { Breakpoint, ThemeColor, ThemeFontWeight } from '@/enums'
import { createBreakpoints } from './createBreakpoints'

export const theme = {
  color: {
    [ThemeColor.info1]: '#0D5DFF',
    [ThemeColor.info2]: '#50A6FF',
    [ThemeColor.info3]: '#E6E8FF',
    [ThemeColor.info4]: '#F5F6FF',
    [ThemeColor.success1]: '#15BE53',
    [ThemeColor.success2]: '#1AB229',
    [ThemeColor.success3]: '#D2F0D5',
    [ThemeColor.success4]: '#F6FEF7',
    [ThemeColor.warning1]: '#F3A10D',
    [ThemeColor.warning2]: '#FFCA66',
    [ThemeColor.warning3]: '#FFE8BD',
    [ThemeColor.warning4]: '#FFFBF5',
    [ThemeColor.danger1]: '#E81D1D',
    [ThemeColor.danger2]: '#FC7377',
    [ThemeColor.danger3]: '#FECDCF',
    [ThemeColor.danger4]: '#FFF5F5',
    [ThemeColor.b100]: '#05070B',
    [ThemeColor.b50]: '#587393',
    [ThemeColor.b40]: '#A3B6CC',
    [ThemeColor.b30]: '#E4EAF1',
    [ThemeColor.b10]: '#F8FAFC',
    [ThemeColor.b0]: '#fff',
    [ThemeColor.electric100]: '#EDF6FF',
    [ThemeColor.electric200]: '#B9DBFF',
    [ThemeColor.electric300]: '#50A6FF',
    [ThemeColor.electric400]: '#50A6FF',
    [ThemeColor.electric500]: '#0F3BBE',
    [ThemeColor.electric900]: '#11235A',
    [ThemeColor.glass0]: '#FFFFFF',
    [ThemeColor.glass100]: '#F5F7FA',
    [ThemeColor.glass200]: '#DAE2F2',
    [ThemeColor.glass300]: '#838DA5',
    [ThemeColor.glass500]: '#505C73',
    [ThemeColor.glass900]: '#141E33',
  },
  font: {
    weight: {
      [ThemeFontWeight.light]: '200',
      [ThemeFontWeight.regular]: '400',
      [ThemeFontWeight.medium]: '500',
      [ThemeFontWeight.semibold]: '600',
      [ThemeFontWeight.bold]: '700',
    },
  },
  baseFontSize: 4,
  breakpoints: createBreakpoints({
    [Breakpoint.xxs]: 0,
    [Breakpoint.xs]: 400,
    [Breakpoint.sm]: 600,
    [Breakpoint.tab]: 769,
    [Breakpoint.md]: 960,
    [Breakpoint.lg]: 1280,
    [Breakpoint.xl]: 1920,
  }),
  transition: 'cubic-bezier(0, 0, 0.2, 1)',
}

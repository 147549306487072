import { Column, ImageFromCdn, Typography } from '@/components'
import { themeColor, toThemePixels } from '@/utils'
import PropTypes from 'prop-types'
import { Trans, useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { AssessmentLoadingStep } from './AssessmentLoadingStep'
import { TrustPilotExtended } from '@/icons/TrustPilotExtended'
import { ThemeColor } from '@/enums'

const PrepareOfferStepBTestimonialContainer = styled(Column)`
  gap: ${toThemePixels(4)};
  padding: ${toThemePixels(4)};
  width: 100%;
  height: 700px;
  position: relative;
`

const StyledImageFromCdnContainer = styled.div`
  position: relative;
  width: 100%;
  height: 370px;
  top: -20px;
  overflow: hidden;
`

const StyledImageFromCdn = styled(ImageFromCdn)`
  height: 100%;
  width: 100%;
  position: absolute;
  object-fit: cover;
`

const GradientOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    to bottom,
    ${themeColor(ThemeColor.b10)},
    rgba(128, 128, 128, 0) 35%,
    rgba(128, 128, 128, 0) 0
  );
  z-index: 1;
`

const useMessages = () => {
  const { t } = useTranslation()

  return {
    steps: [
      t('assessmentLoadingStep1'),
      t('liabilityAssessmentLoadingStep2'),
      t('liabilityAssessmentLoadingStep3'),
      t('assessmentLoadingStep5'),
    ],
    prepareOfferImageTitle: t('prepareOfferImageTitle'),
    prepareOfferImageContent: t('prepareOfferImageContent'),
  }
}

export const PrepareOfferStepB = ({ onNext }) => {
  const messages = useMessages()

  return (
    <AssessmentLoadingStep onNext={onNext} stepsMessages={messages.steps} />
  )
}

PrepareOfferStepB.propTypes = {
  onNext: PropTypes.func.isRequired,
}

export const PrepareOfferStepBTestimonial = () => {
  const { t } = useTranslation()

  return (
    <PrepareOfferStepBTestimonialContainer>
      <StyledImageFromCdnContainer>
        <GradientOverlay />
        <StyledImageFromCdn name="carrier-waterfall" type="png" />
      </StyledImageFromCdnContainer>
      <Typography color="b100" variant="subheadline">
        <Trans
          i18nKey="prepareOfferTestimonial.title"
          components={[
            <Typography color="info1" variant="subheadline" />,
            <Typography color="info1" variant="subheadline" />,
          ]}
        />
      </Typography>
      <Typography color="b50" variant="description">
        {t('prepareOfferTestimonial.subtitle')}
      </Typography>
      <TrustPilotExtended width={160} />
    </PrepareOfferStepBTestimonialContainer>
  )
}

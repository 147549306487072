import { ThemeColor } from '@/enums'
import { Image } from '../Image'
import { Typography } from '../Typography'
import { Container, TextBlock } from './styles'

interface AdvisorBubbleProps {
  text: string
}

export const AdvisorBubble = ({ text }: AdvisorBubbleProps) => (
  <Container>
    <Image name="chatblock" type="png" />
    <TextBlock>
      <Typography color={ThemeColor.b50} variant="p1Body">
        {text}
      </Typography>
    </TextBlock>
  </Container>
)

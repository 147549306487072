import { ComponentColors } from '@/enums'
import { cleanPercentage } from '@/utils'
import { Container, InnerContainer } from './styles'

interface LineProgressProps {
  percentage: number
  height?: string | number
  width?: string | number
  color?: keyof typeof ComponentColors
}

export const LineProgress = ({
  percentage,
  height = '4px',
  width,
  color = ComponentColors.text,
}: LineProgressProps) => (
  <Container color={color} height={height} width={width}>
    <InnerContainer
      color={color}
      height={height}
      width={cleanPercentage(percentage)}
    />
  </Container>
)

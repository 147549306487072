import { Column, Typography } from '@/components'
import { ThemeColor } from '@/enums'
import { theme } from '@/theme'
import {
  Frown,
  Megaphone,
  PaintBucket,
  ShieldCheck,
  Stethoscope,
} from 'lucide-react'
import { useTranslation } from 'react-i18next'
import { ProtectionItem, WhatIsProtectedSection } from './styles'
import { useCategoryTranslations } from '@/utils/hooks/useCategoryTranslations'

const categoryIcons = {
  LI: [Frown, Stethoscope, PaintBucket, Megaphone],
} as const

export const WhatIsProtected = ({ categoryId }: { categoryId: string }) => {
  const { t } = useTranslation()
  const categoryTranslations = useCategoryTranslations(categoryId)
  const categoryUsps: string[] = categoryTranslations.usps

  if (categoryUsps.length === 0 || categoryUsps.every((item) => item === ''))
    return null

  return (
    <WhatIsProtectedSection>
      <Typography bold variant="h3">
        {t('whatIsProtected')}
      </Typography>
      <Column gap="12px">
        {categoryUsps.map((usp, index) => {
          const Icon =
            categoryIcons[categoryId as keyof typeof categoryIcons]?.[index] ||
            ShieldCheck
          return usp ? (
            <ProtectionItem key={usp} gap="8px">
              <Icon color={theme.color[ThemeColor.electric500]} />
              <p>{usp}</p>
            </ProtectionItem>
          ) : null
        })}
      </Column>
    </WhatIsProtectedSection>
  )
}

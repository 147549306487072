import { ComponentColors, ThemeColor } from '@/enums'
import {
  primaryColorFromComponentColor,
  themeColor,
  themeTransition,
  toThemePixels,
} from '@/utils'
import styled, { keyframes } from 'styled-components'
import { prop } from 'styled-tools'

interface ContainerProps {
  height?: string | number
  width?: string | number
  color?: keyof typeof ComponentColors
}

export const Container = styled.div<ContainerProps>`
  background-color: ${themeColor(ThemeColor.b0)};
  width: ${prop('width')};
  height: ${prop('height')};
  border-radius: ${toThemePixels(3)};
  background-color: ${themeColor(ThemeColor.b10)};
  outline: 1px solid ${themeColor(ThemeColor.b30)};
`

const progress = keyframes`
0% {
  width: 0;
}
`

export const InnerContainer = styled.div<ContainerProps>`
  width: ${prop('width')}%;
  height: ${prop('height')};
  border-radius: ${toThemePixels(1)};
  background-color: ${primaryColorFromComponentColor};
  transition: ${themeTransition('width')};

  animation: ${progress} 0.4s linear;
`

import { Column, Fixed, Row, Typography } from '@/components'
import { Breakpoint, ThemeColor } from '@/enums'
import { useSegment } from '@/modules'
import { useSetButtonText } from '@/providers'
import { theme } from '@/theme'
import { themeBreakpointDown, themeColor, useIsMobile } from '@/utils'
import { Button } from '@surein/ui'
import {
  ArrowRight,
  Coffee,
  CopyCheck,
  Crown,
  HeartHandshake,
  LucideProps,
} from 'lucide-react'
import { ComponentType } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const Container = styled(Fixed)<{
  top?: number
  bottom?: number
  left?: number
  right?: number
  zIndex?: number
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  background-color: ${themeColor(ThemeColor.b10)};
  z-index: 120;

  ${themeBreakpointDown('sm')} {
    z-index: 0;
  }
`

const Content = styled.div`
  width: 456px;
  border: 1px solid ${themeColor(ThemeColor.glass200)};
  background-color: white;
  padding: 24px;
  margin: 12px 16px;
  border-radius: 24px;

  ${themeBreakpointDown('sm')} {
    margin: 0;
    height: 100%;
    border-radius: 0;
    border: none;
  }
`

const InnerContent = styled(Column)`
  background-color: ${themeColor(ThemeColor.glass100)};
  border-radius: 16px;
  gap: 16px;
  padding: 16px;

  svg {
    flex-shrink: 0;
  }
`

const sureinUsps = ['simple', 'protection', 'independent', 'free']

const sureinUspsIconMap: { [key: string]: ComponentType<LucideProps> } = {
  simple: Coffee,
  protection: HeartHandshake,
  independent: CopyCheck,
  free: Crown,
}

interface BeforeYouStartStepProps {
  onNext: () => void
}

interface UspsContentProps {
  usps: string[]
}

const UspsContent = ({ usps }: UspsContentProps) => {
  const { t } = useTranslation()

  return (
    <>
      {usps.map((uspKey, index) => {
        const Icon = sureinUspsIconMap[uspKey]

        return (
          <Row key={index} gap="8px">
            <Icon color={theme.color.info1} size={24} />
            <Typography variant="description">
              {t(`sureinUsps.${uspKey}`)}
            </Typography>
          </Row>
        )
      })}
    </>
  )
}

export const BeforeYouStartStep = ({ onNext }: BeforeYouStartStepProps) => {
  const { t } = useTranslation()
  const { isMobile } = useIsMobile(Breakpoint.sm)
  const analytics = useSegment()

  const handleNext = async () => {
    await analytics.track('before_you_start_next_clicked')
    onNext()
  }

  useSetButtonText(() => t('getStarted'), [])

  return (
    <>
      {isMobile ? (
        <Column gap="24px">
          <Typography variant="itemTitle">
            {t('beforeYouStartTitle')}
          </Typography>
          <InnerContent>
            <UspsContent usps={sureinUsps} />
          </InnerContent>
        </Column>
      ) : (
        <Container left={0} right={0} top={0} bottom={0}>
          <Content>
            <Column gap="24px">
              <Typography variant="itemTitle">
                {t('beforeYouStartTitle')}
              </Typography>
              <InnerContent>
                <UspsContent usps={sureinUsps} />
              </InnerContent>
              <Button
                onClick={handleNext}
                variant="primary"
                shape="square"
                suffixIcon={ArrowRight}
              >
                {t('getStarted')}
              </Button>
            </Column>
          </Content>
        </Container>
      )}
    </>
  )
}

export const BusinessOrgs = {
  gmbh: 'GmbH',
  ug: 'UG',
  eu: 'Einzelfirma',
  ek: 'e.K.',
  gbr: 'GbR',
  ohg: 'OHG',
  kg: 'KG',
  ag: 'AG',
}

export const AssessmentStatus = {
  pending: 'pending',
  inProgress: 'in_progress',
  completed: 'completed',
}

export const AssessmentType = {
  firstTimeUser: 'first_time_user',
  firstLocation: 'first_location',
  multipleLocation: 'multiple_location',
}

export const AssessmentStepKeys = {
  howCanWeHelp: 'how_can_we_help',
  userProfile: 'user_profile',
  businessType: 'business_type',
  locationType: 'location_type',
  companyDetails: 'company_details',
  companyFoundedDate: 'company_founded_date',
  contactData: 'contact_data',
  locationsCount: 'locations_count',
  locationDetails: 'location_details',
  locationOpenSeason: 'location_open_season',
  employeesCount: 'employees_count',
  lastYearFinances: 'last_year_finances',
  locationActivities: 'location_activities',
  locationRent: 'location_rent',
  currentInsurances: 'current_insurances',
  powerOfAttorney: 'power_of_attorney',
  prepareData: 'prepare_data',
  createAccount: 'create_account',
  createAccountB: 'create_account_b',
  approxRevenue: 'approx_revenue',
  approxLaborCosts: 'approx_labor_costs',
  howCanWeHelpLead: 'how_can_we_help_lead',
  companyFoundedDateLead: 'company_founded_date_lead',
  createLead: 'create_lead',
  inventory: 'inventory',
  startDate: 'start_date',
  prepareOffer: 'prepare_offer',
  prepareOfferB: 'prepare_offer_b',
  constructionDate: 'construction_date',
  constructionDetails: 'construction_details',
  constructionArea: 'construction_area',
  notes: 'notes',
  beforeYouStart: 'before_you_start',
}

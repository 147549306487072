import { Column, Row, Typography } from '@/components'
import { Categories, ThemeColor } from '@/enums'
import { theme } from '@/theme'
import { Card } from '@surein/ui'
import {
  Frown,
  Megaphone,
  PaintBucket,
  ShieldCheck,
  Stethoscope,
} from 'lucide-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const categoryIcons = {
  LI: [Frown, Stethoscope, PaintBucket, Megaphone],
}

const WhatIsProtectedCard = styled(Card)`
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 16px;
`

const UspRow = styled(Row)`
  gap: 8px;

  svg {
    flex-shrink: 0;
  }
`

export const WhatIsProtected = ({ categoryId }) => {
  const { t } = useTranslation()
  const categoryUsps =
    categoryId in Categories
      ? t(`categories.${Categories[categoryId].key}.usps`, {
          returnObjects: true,
        })
      : []

  if (categoryUsps.length === 0 || categoryUsps.every((item) => item === ''))
    return null

  return (
    <WhatIsProtectedCard variant="borderless">
      <Typography bold color={ThemeColor.info1} variant="badgeText">
        {t('whatIsProtected')}
      </Typography>
      <Column gap="12px">
        {categoryUsps.map((usp, index) => {
          const Icon = categoryIcons?.[categoryId]?.[index] || ShieldCheck
          return usp ? (
            <UspRow key={usp} gap="8px">
              <Icon color={theme.color[ThemeColor.electric500]} />
              <Typography key={usp} gap="8px" variant="p2Body">
                {usp}
              </Typography>
            </UspRow>
          ) : null
        })}
      </Column>
    </WhatIsProtectedCard>
  )
}

WhatIsProtected.propTypes = {
  categoryId: PropTypes.string,
}

import { FormPhone, TextWithLink } from '@/components'
import { Categories } from '@/enums'
import { useSegment } from '@/modules/analytics'
import { useFormContext, useNextLoading, useOnNextClick } from '@/providers'
import { paths } from '@/routes/paths'
import { tempAuthQueryKeys } from '@/services'
import {
  deleteLocalStorageItems,
  makeUrlWithLanguage,
  openInNewTab,
  propTypeFromEnumKeys,
  setLocalStorageItem,
  useLanguage,
} from '@/utils'
import jwtDecode from 'jwt-decode'
import PropTypes from 'prop-types'
import { useCallback } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { useOutletContext, useSearchParams } from 'react-router-dom'
import { useAssessmentValues } from '../helpers'
import { ButtonLinkWrapper } from './components/ContactDataStepA'

const useMessages = () => {
  const { t } = useTranslation()

  return {
    name: t('name'),
    firstName: t('firstName'),
    lastName: t('surname'),
    phoneNumber: t('phone'),
    email: t('email'),
    acceptTerms: t('acceptTerms'),
  }
}

const makeTermsUrl = makeUrlWithLanguage(paths.terms)
const makePolicyUrl = makeUrlWithLanguage(paths.policy)

export const CreateAccountStepB = ({
  categoryId,
  mutation,
  transformSubmitValues,
}) => {
  const queryClient = useQueryClient()
  const language = useLanguage()
  const messages = useMessages()
  const {
    values: { createAccountB: values, ...assessmentValues },
  } = useAssessmentValues()
  const { customValidate } = useFormContext()
  const localStorageKeys = [
    `${categoryId}-values`,
    `${categoryId}-current-step`,
    `${categoryId}-confirmation-id`,
    `${categoryId}-recommendation-id`,
  ]
  const [searchParams] = useSearchParams()
  const analytics = useSegment()

  // Marketing specific params
  const { marketingPid, marketingMid } = useOutletContext()
  const partner = searchParams.get('partner')

  const save = useCallback(
    () =>
      new Promise((resolve, reject) => {
        customValidate({
          onSuccess: () => {
            const [name, ...surname] = assessmentValues.contactData.name.split(
              ' ',
            )
            const formattedSurname = surname.join(' ') || '_'

            const body = {
              ...assessmentValues.contactData,
              ...values,
              name,
              surname: formattedSurname,
              partner,
              marketingPid,
              marketingMid,
            }

            deleteLocalStorageItems(localStorageKeys)

            if (transformSubmitValues) {
              body.assessmentValues = transformSubmitValues(assessmentValues)
            }

            mutation.mutate(body, {
              onSuccess: async ({ accessToken }) => {
                queryClient.resetQueries(tempAuthQueryKeys.recommendation)
                deleteLocalStorageItems(localStorageKeys)
                if (accessToken) {
                  const { user_id: userId } = jwtDecode(accessToken)
                  setLocalStorageItem('access-token', accessToken)
                  analytics.identify(userId, {
                    email: body.email,
                    first_name: body.name,
                    last_name: body.surname,
                  })
                  await analytics.track('ssf_lead_gen_email_success')
                }
                resolve()
              },
            })
          },
          onFail: reject,
        })
      }),
    [values],
  )

  const saveAndTrack = useCallback(async () => {
    await save()
    await analytics.track('ssf_lead_gen_phone_number')
  }, [values])

  useOnNextClick(saveAndTrack)

  useNextLoading(() => mutation.isLoading, [mutation.isLoading])

  return (
    <>
      <FormPhone label={messages.phoneNumber} name="phone" />
      <TextWithLink>
        <Trans
          components={[
            <ButtonLinkWrapper
              onClick={() => openInNewTab(makeTermsUrl(language))}
              size="small"
            />,
            <ButtonLinkWrapper
              onClick={() => openInNewTab(makePolicyUrl(language))}
              size="small"
            />,
          ]}
          i18nKey={messages.acceptTerms}
        />
      </TextWithLink>
    </>
  )
}

CreateAccountStepB.propTypes = {
  categoryId: propTypeFromEnumKeys(Categories),
  mutation: PropTypes.shape({
    isLoading: PropTypes.bool,
    mutate: PropTypes.func,
  }),
  transformSubmitValues: PropTypes.func,
}

import {
  AdvisorBubble,
  ButtonLink,
  Column,
  Fixed,
  FormInput,
  FormPhone,
  ImageFromCdn,
  LineProgress,
  ModalBase,
  Row,
  TextWithLink,
  Typography,
} from '@/components'
import { ContinueButton } from '@/components/organisms/Stepper/styles'
import { ComponentColors, ThemeColor } from '@/enums'
import { useSegment } from '@/modules'
import { BasePage } from '@/pages/RecommendationPage/BasePage'
import { mockResponse } from '@/pages/RecommendationPage/helpers'
import { useContactModal, useFormContext } from '@/providers'
import { tempAuthQueryKeys, useCreateAccountV2 } from '@/services'
import { theme } from '@/theme'
import {
  deleteLocalStorageItems,
  noop,
  setLocalStorageItem,
  themeBreakpointDown,
  themeColor,
  useApiErrorMessage,
  useGoToPath,
  useSteps,
} from '@/utils'
import { Button } from '@surein/ui'
import jwtDecode from 'jwt-decode'
import { ArrowRightIcon, ChevronLeftIcon, Phone, Shield } from 'lucide-react'
import { Trans, useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { useOutletContext, useSearchParams } from 'react-router-dom'
import styled from 'styled-components'
import { useAssessmentValues } from '../../helpers'
import { paths } from '@/routes/paths'

const Container = styled(Fixed)<{
  top?: number
  bottom?: number
  left?: number
  right?: number
  zIndex?: number
}>`
  height: 100%;
  width: 100%;
  background-color: ${themeColor(ThemeColor.b10)};
  z-index: 120;

  ${themeBreakpointDown('sm')} {
    z-index: 0;
  }
`

const ModalContent = styled(Column)`
  height: 600px;
  width: 520px;
`

const Header = styled(Column)`
  padding: 0 8px;
  gap: 16px;

  img {
    width: 74px;
    height: 20px;
    border-radius: 0;
  }
`

const Content = styled(Column)`
  flex-grow: 1;
  gap: 24
  padding: 16px 8px;
`

const Footer = styled(Row)`
  align-items: center;
  justify-content: space-between;
  padding: 16px 8px;
`

const TitleSection = styled(Column)`
  margin-top: 24px;
`

const NameEmailFormWrapper = styled(Column)`
  > div {
    margin-bottom: 8px;
  }
`

const NameAndEmailSubStep = () => {
  const { t } = useTranslation()

  return (
    <Column gap="24px">
      <TitleSection>
        <Typography variant="itemTitle" color={ThemeColor.b100}>
          {t('createLeadTitle')}
        </Typography>
        <Typography variant="description" color={ThemeColor.b50}>
          {t('createLeadSubtitle')}
        </Typography>
      </TitleSection>
      <Column gap="8px">
        <NameEmailFormWrapper>
          {/* 
          @ts-expect-error needs to be properly typed */}
          <FormInput
            label={t('name')}
            name="name"
            placeholder="John Müller"
            showErrorHelperText={false}
          />
          {/* 
          @ts-expect-error needs to be properly typed */}
          <FormInput
            label={t('email')}
            name="email"
            placeholder="example@mail.com"
            showErrorHelperText={false}
          />
        </NameEmailFormWrapper>
        <Row alignItems="center" gap="4px">
          <Shield color={theme.color[ThemeColor.success1]} />
          <Typography singleLine variant="footnote">
            {t('ssl')}
          </Typography>
        </Row>
      </Column>
    </Column>
  )
}

interface PhoneNumberSubStepProps {
  errorCode?: string
}

const PhoneNumberSubStep = ({ errorCode }: PhoneNumberSubStepProps) => {
  const { t } = useTranslation()
  const { errorMessages } = useApiErrorMessage()
  const goToRoot = useGoToPath(paths.authRedirect)

  return (
    <Column gap="24px">
      <TitleSection>
        <Typography variant="itemTitle" color={ThemeColor.b100}>
          {t('phoneModalTitle')}
        </Typography>
      </TitleSection>
      <Column gap="8px">
        <FormPhone name="phone" placeholder="0123 456 7890" noMargin />
        <Row alignItems="center" gap="4px">
          <Shield color={theme.color[ThemeColor.success1]} />
          <Typography singleLine variant="footnote">
            {t('ssl')}
          </Typography>
        </Row>
      </Column>
      <AdvisorBubble text={t('mockRecommendationModalText.advisorText')} />
      {errorCode && errorCode && (
        <Typography
          color={ThemeColor.danger1}
          textAlign="center"
          variant="button"
        >
          <TextWithLink color={ThemeColor.danger1} fontSize="16px">
            <Trans
              // @ts-expect-error TODO: fix type
              components={
                // @ts-expect-error TODO: fix type
                <ButtonLink
                  color={ComponentColors.danger}
                  onClick={goToRoot}
                  underline
                />
              }
              i18nKey={
                // @ts-expect-error coupling to infrastructure concern - TODO: handle error in api/service layer
                errorMessages[errorCode]
              }
            />
          </TextWithLink>
        </Typography>
      )}
    </Column>
  )
}

interface ContactDataStepBProps {
  onBack: () => void
  categoryId: string
  // TODO: Type when Stepper types are being set
  transformSubmitValues?: (values: any) => any
  onFinish: () => void
}

export const ContactDataStepB = ({
  categoryId,
  onBack: backToFunnel,
  transformSubmitValues,
  onFinish,
}: ContactDataStepBProps) => {
  const { t } = useTranslation()
  const { openContact } = useContactModal()
  const queryClient = useQueryClient()
  const { values: assessmentValues } = useAssessmentValues()
  const createAccountMutation = useCreateAccountV2(categoryId)
  const { customValidate } = useFormContext()

  const [searchParams] = useSearchParams()
  const analytics = useSegment()

  const steps = [
    { key: 'nameEmail', component: NameAndEmailSubStep },
    { key: 'phoneNumber', component: PhoneNumberSubStep },
  ]

  // Marketing specific params
  const { marketingPid, marketingMid } = useOutletContext<{
    marketingPid: string
    marketingMid: string
  }>()
  const partner = searchParams.get('partner')

  const localStorageKeys = [
    `${categoryId}-values`,
    `${categoryId}-current-step`,
    `${categoryId}-confirmation-id`,
    `${categoryId}-recommendation-id`,
  ]

  const handleSubmit = () => {
    const [name, ...surname] = assessmentValues.contactData.name.split(' ')
    const formattedSurname = surname.join(' ') || '_'

    const body = {
      assessmentValues,
      name,
      surname: formattedSurname,
      phone: assessmentValues.contactData.phone,
      email: assessmentValues.contactData.email,
      partner,
      marketingPid,
      marketingMid,
    }

    if (transformSubmitValues) {
      body.assessmentValues = transformSubmitValues(body.assessmentValues)
    }

    createAccountMutation.mutate(body, {
      onSuccess: async (data: { accessToken: string }) => {
        deleteLocalStorageItems(localStorageKeys)
        queryClient.resetQueries(tempAuthQueryKeys.recommendation)
        if (data?.accessToken) {
          const { user_id: userId } = jwtDecode<{ user_id: string }>(
            data?.accessToken,
          )
          setLocalStorageItem('access-token', data?.accessToken)
          analytics.identify(userId, {
            email: body.email,
            first_name: body.name,
            last_name: body.surname,
          })
          await analytics.track('ssf_recommendation_phone_number')
          onFinish()
        }
      },
      // @ts-expect-error fix type on the action - our API client is overwriting response types in interceptors
      onError: async (data: { response?: { data?: { code: string } } }) => {
        if (data.response?.data?.code === 'USER_ALREADY_EXISTS') {
          await analytics.track('ssf_returning_user')
        } else {
          await analytics.track('ssf_general_error')
        }
      },
    })
  }

  const { isLast, isFirst, index, back, next } = useSteps({
    initialStep: 'nameEmail',
    steps,
    keyPropName: 'key',
  })

  const CurrentStepComponent = steps[index].component

  const handleBack = () => {
    if (isFirst) {
      backToFunnel()
    } else {
      back()
    }
  }

  const handleNext = () => {
    if (isLast) {
      handleSubmit()
    } else {
      next()
    }
  }

  return (
    <Container left={0} right={0} top={0} bottom={0}>
      <BasePage
        data={mockResponse}
        noBack
        onChoose={noop}
        handleFileClick={noop}
      />

      <ModalBase isOpen padding="16px" closable={false}>
        <ModalContent>
          <Header>
            <Row alignItems="center" justifyContent="space-between">
              <ImageFromCdn
                name="SureinLogo2024"
                onClick={noop}
                type="svg"
                width={74}
              />
              <Button
                icon={() => (
                  <Phone color={theme.color[ThemeColor.success1]} size={16} />
                )}
                onClick={openContact}
                size="sm"
                variant="muted"
              />
            </Row>
            <LineProgress
              color={ComponentColors.primary}
              height="8px"
              percentage={0.9 * 100}
              width="176px"
            />
          </Header>
          <Content>
            <CurrentStepComponent
              // @ts-expect-error coupling to infrastructure concern - TODO: handle error in api/service layer
              errorCode={createAccountMutation.error?.response?.data?.code}
            />
          </Content>
          <Footer>
            <Button
              disabled={createAccountMutation.isLoading}
              icon={() => <ChevronLeftIcon size={24} />}
              onClick={handleBack}
              shape="square"
              size="md"
              variant="muted"
            />
            <ContinueButton
              loading={createAccountMutation.isLoading}
              onClick={() =>
                customValidate({
                  onSuccess: handleNext,
                })
              }
              reverted
              shape="square"
              suffixIcon={ArrowRightIcon}
              variant="primary"
            >
              {isLast ? t('viewOffer') : t('continue')}
            </ContinueButton>
          </Footer>
        </ModalContent>
      </ModalBase>
    </Container>
  )
}

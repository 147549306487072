import { config } from '@/config'
import { AssessmentStepKeys, AssessmentType, Categories } from '@/enums'
import { useAuth } from '@/providers'
import { paths } from '@/routes/paths'
import { useCreateAccountV2 } from '@/services'
import {
  deleteLocalStorageItems,
  dynamicObjectPropType,
  extractFunnelFromPathname,
  goToExternalUrl,
  propTypeFromEnumKeys,
  pushEventToDataLayer,
  useGoToPath,
  useLanguage,
  useModal,
  usePrevious,
} from '@/utils'
import PropTypes from 'prop-types'
import { identity, last } from 'ramda'
import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { AssessmentStepper } from '../../AssessmentStepper'
import {
  getStoredAssessmentValues,
  getStoredCurrentStep,
  makeStoredAssessmentValuesKey,
  makeStoredCurrentStepKey,
  setStoredAssessmentValues,
  setStoredCurrentStep,
  useForwardFunnelSource,
} from '../../helpers'
import { MockRecommendation } from '../MockRecommendation'

const useMessages = () => {
  const { t } = useTranslation()

  return {
    propsMessages: {
      helperText: t('approxHelperText'),
      steps: [
        t('assessmentLoadingStep1'),
        t('liabilityAssessmentLoadingStep2'),
        t('liabilityAssessmentLoadingStep3'),
        t('assessmentLoadingStep5'),
      ],
    },
    subtitle: t('leadStepSubtitle'),
    titles: {
      [AssessmentStepKeys.businessType]: t('businessTypeTitle'),
      [AssessmentStepKeys.companyDetails]: t('companyDetailsTitle'),
      [AssessmentStepKeys.companyFoundedDateLead]: t('companyFoundedDateTitle'),
      [AssessmentStepKeys.employeesCount]: t('employeesCountTitle'),
      [AssessmentStepKeys.approxRevenue]: t('approxRevenueTitle'),
      [AssessmentStepKeys.inventory]: t('inventoryTitle'),
      [AssessmentStepKeys.approxLaborCosts]: t('approxLaborCostsTitle'),
      [AssessmentStepKeys.prepareData]: t('preparingOffer'),
      [AssessmentStepKeys.prepareOfferB]: t('preparingOffer'),
      [AssessmentStepKeys.contactData]: t('createLeadTitle'),
      [AssessmentStepKeys.createAccount]: t('createAccountTitle'),
      [AssessmentStepKeys.createAccountB]: t('createAccountTitle'),
      [AssessmentStepKeys.startDate]: t('startDateTitle'),
    },
  }
}

const additionalSteps = [
  AssessmentStepKeys.prepareOfferB,
  AssessmentStepKeys.contactData,
  AssessmentStepKeys.createAccountB,
]

const additionalStepsB = [
  { key: AssessmentStepKeys.prepareOfferB, stepIndex: 2 },
  { key: AssessmentStepKeys.contactData, stepIndex: 2 },
  { key: AssessmentStepKeys.createAccountB, stepIndex: 2 },
]

export const ConditionalOfferAssessment = ({
  onLogoClick,
  categoryId,
  stepKeys,
  makeStepProps,
  stepperBreadcrumbTitles,
  transformSubmitValues = identity,
}) => {
  const language = useLanguage()
  const { isAuthenticated } = useAuth()
  const hasBreadcrumbs = !!stepperBreadcrumbTitles
  const messages = useMessages()

  const prevCategoryId = usePrevious(categoryId)

  const stepLocalStorageKey = makeStoredCurrentStepKey(categoryId)
  const valuesLocalStorageKey = makeStoredAssessmentValuesKey(categoryId)

  const createAccountMutation = useCreateAccountV2(categoryId)

  const funnelProduct = extractFunnelFromPathname()
  const queryParams = useForwardFunnelSource()

  const goToRecommendation = useGoToPath(
    paths.viewSsfOfferWithKey(categoryId),
    // when funnel source is not a concern (new tracking fully set up), this will be
    // transformed to just forward the funnel_product instead of spreading the query params
    { ...queryParams, funnel_product: funnelProduct },
  )

  const recommendationState = useModal()

  const handleCalculationSuccessful = () => {
    recommendationState.open()
    pushEventToDataLayer('Step Change', {
      funnel_step: 'contact_data_recommendation',
      funnel_product: extractFunnelFromPathname(),
    })
  }

  const handleFinish = () => {
    goToRecommendation()
    pushEventToDataLayer('Step Change', {
      funnel_step: 'recommendation_screen',
      funnel_product: funnelProduct,
    })

    recommendationState.close()
  }

  const stepProps = useMemo(
    () => ({
      ...makeStepProps(messages.propsMessages),
      [AssessmentStepKeys.createAccount]: {
        categoryId,
        goToStepName: last(stepKeys),
        mutation: createAccountMutation,
        transformSubmitValues,
      },
      [AssessmentStepKeys.createAccountB]: {
        categoryId,
        mutation: createAccountMutation,
        transformSubmitValues,
      },
      [AssessmentStepKeys.contactData]: {
        categoryId,
        handleCalculationSuccessful,
        transformSubmitValues,
        onFinish: handleFinish,
      },
      [AssessmentStepKeys.prepareData]: {
        stepsMessages: messages.propsMessages.steps,
        hideButtons: true,
      },
      [AssessmentStepKeys.prepareOfferB]: {
        hideButtons: true,
        categoryId,
        transformSubmitValues,
      },
    }),
    [language, categoryId, JSON.stringify(createAccountMutation)],
  )

  useEffect(() => {
    if (isAuthenticated) goToExternalUrl(`https://${config.appDomain}`)
  }, [])

  // update stored values keys
  useEffect(() => {
    if (prevCategoryId && categoryId !== prevCategoryId) {
      setStoredAssessmentValues(
        categoryId,
        getStoredAssessmentValues(prevCategoryId),
      )
      setStoredCurrentStep(categoryId, getStoredCurrentStep(prevCategoryId))
      deleteLocalStorageItems([
        makeStoredAssessmentValuesKey(prevCategoryId),
        makeStoredCurrentStepKey(prevCategoryId),
      ])
    }
  }, [categoryId])

  if (recommendationState.isOpen) {
    return (
      <MockRecommendation
        categoryId={categoryId}
        onBack={recommendationState.close}
        onFinish={handleFinish}
        transformSubmitValues={transformSubmitValues}
      />
    )
  }

  return (
    <AssessmentStepper
      initialStep={getStoredCurrentStep(categoryId)}
      initialValues={getStoredAssessmentValues(categoryId)}
      messages={messages}
      onFinish={handleFinish}
      onLogoClick={onLogoClick}
      skipSuccessPage
      stepKeys={[
        ...stepKeys,
        ...(hasBreadcrumbs ? additionalStepsB : additionalSteps),
      ]}
      stepLocalStorageKey={stepLocalStorageKey}
      stepperBreadcrumbTitles={stepperBreadcrumbTitles}
      stepProps={stepProps}
      type={AssessmentType.firstTimeUser}
      valuesLocalStorageKey={valuesLocalStorageKey}
    />
  )
}

ConditionalOfferAssessment.propTypes = {
  categoryId: propTypeFromEnumKeys(Categories),
  makeStepProps: PropTypes.func.isRequired,
  onLogoClick: PropTypes.func,
  stepKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
  stepperBreadcrumbTitles: dynamicObjectPropType,
  transformSubmitValues: PropTypes.func,
}
